<template>
  <div class="sub-right">
    <span :class="classObj">得分</span>/分值：<span :class="classObj">{{childItem.my_score}}</span>/{{childItem.score}}
  </div>
</template>

<script>
export default {
  props: {
    childItem: Object
  },
  computed: {
    classObj() {
      return {
        error: this.childItem.submit_result === 0,
        right: this.childItem.submit_result === 1
      } 
    }
  },
}
</script>

<style lang="scss" scoped>
.sub-right {
  flex-shrink: 0;
  padding-left: 20px;
  .right {
    color: #34B979;
  }
  .error {
    color: red;
  }
}
</style>