<template>
  <div class="star-container fr st3">
    <span class="first-star" :class="['diff' + val]"></span>
    <span class="second-star"></span>
  </div>
</template>

<script>
export default {
  props: {
    val: {
      type: Number,
      default: 10 // 默认是五星
    },
  },
}
</script>

<style lang="scss" scoped>
.star-container {
  width: 150px;
  height: 20px;
  position: relative;
  .first-star {
    position: absolute;
    left: 0;
    top: 0;
    width: 26.6666%;
    height: 100%;
    background: url('../assets/images/light_star.png');
    background-size: cover;
    &.diff0 {
      width: 0%;
    }
    &.diff1 {
      width: 6.6666%;
    }
    &.diff3 {
      width: 20%;
    }
    &.diff3 {
      width: 26.6666%;
    }
    &.diff4 {
      width: 39%;
    }
    &.diff5 {
      width: 46.6666%;
    }
    &.diff6 {
      width: 60%;
    }
    &.diff7 {
      width: 66.6666%;
    }
    &.diff8 {
      width: 80%;
    }
    &.diff9 {
      width: 86.6666%;
    }
    &.diff10 {
      width: 100%;
    }
  }
  .second-star {
    width: 100%;
    height: 100%;
    background: url('../assets/images/dark_star.png');
    background-size: cover;
  }
}
</style>