<template>
  <div class="left-section fr" :class="[result === 1 ? 'left-right' : 'left-wrong']">
    {{result === 1 ? '回答正确' : '回答错误'}}
    <img 
      :src="
        result === 1 ? 
        require('../assets/images/answer_right.png') : 
        require('../assets/images/answer_wrong.png')
      " 
    />
  </div>
</template>

<script>
export default {
  props: {
    result: Number,
  },
}
</script>

<style lang="scss" scoped>
.left-section {
  align-items: center;
  flex-shrink: 0;
  font-size: 18px;
  &.left-wrong {
    color: #E54A2B;
  }
  &.left-right {
    color: #44BD81;
  }
  img {
    width: 11px;
    margin-left: 10px;
  }
}
</style>