<template>
  <div 
    class="sidebar" 
    :class="{
      isFixed: isFixed,
      isPad: app.isPad
    }" 
    :style="styleObject"
  >
    <slot name="answerSheetHeader" />
    <div class="sidebar-middle">
      <div class="middle-top fr">
        <span class="middle-top-title b">答题卡</span>
        <div class="middle-top-group fr">
          <div class="middle-top-section">
            <span class="section-bg unfinish"></span>
            <span class="section-title">未做</span>
          </div>
          <div class="middle-top-section">
            <span class="section-bg finish"></span>
            <span class="section-title">已做</span>
          </div>
        </div>
      </div>
      <div class="middle-bottom">
        本卷共 <span class="finish-count">{{finishQuestionCount}}</span>/{{size}}道题，剩余<span class="unfinish-count">{{size - finishQuestionCount}}</span>道题
      </div>
    </div>
    <div class="sidebar-bottom">
      <div class="sidebar-subquestions" v-for="(item, index) in questionList" :key="index">
        <span class="subquestions-title">{{item.origin_cate_name}}题（{{item.questions.length}}）</span>
        <div class="subquestions-nos fr">
          <span 
            class="no"
            :class="{active: isActive(childItem.id)}"
            v-for="(childItem, childIndex) in getOriginQuestions(item.questions)"
            :key="childIndex"
            @click="handleFocusQuestion(index, childItem.index)"
          >{{childItem.order_id}}</span>
        </div>
      </div>
      <slot name="answerSheetBtn" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    paperObj: {
      type: Object,
      default: () => {}
    },
    size: {
      default: 0
    },
    questionList: {
      type: Array,
      default: () => []
    },
    answers: {
      type: Array,
      default: () => []
    },
    remainCount: null
  },
  data() {
    return {
      isFixed: false,
      styleObject: {
        top: '0px'
      }
    }
  },
  inject: ['app'],
  computed: {
    getOriginAnswers() {
      let tmpArr = [], idx = 0
      this.answers.forEach(v => {
        if (v.parent_id) { // 有子题
          idx = tmpArr.findIndex(cv => cv.some(scv => scv.parent_id === v.parent_id))
          if (idx >= 0) {
            tmpArr[idx].push(v)
          } else {
            tmpArr.push([v])
          }
        } else {
          idx = tmpArr.findIndex(cv => cv.some(scv => scv.question_id === v.question_id))
          if (idx >= 0) {
            tmpArr[idx].push(v)
          } else {
            tmpArr.push([v])
          }
        }
      })
      return tmpArr
    },
    // 返回已做题的数量
    finishQuestionCount() {
      let sum = 0
      
      this.getOriginAnswers.forEach(v => {
        if (v[0].type === 101) {
          if (v.every(cv => cv.value)) {
            sum++
          }
        } else {
          if (v.every(cv => cv.value)) {
            sum++
          }
        }
      })
      return sum
    }
  },
  watch: {
    finishQuestionCount() {
      this.$emit('update:remainCount', this.size - this.finishQuestionCount)
    }
  },
  mounted () {
    if (this.app.isPad) {
      this.styleObject = null
      return
    }
    window.addEventListener('scroll', () => {
      let scrollTop = document.documentElement.scrollTop
      this.styleObject.top = '0px'
      if (scrollTop > 245) {
        this.isFixed = true
        this.styleObject.top = parseInt(scrollTop - 245) + 'px'
      } else {
        this.isFixed = false
        this.styleObject.top = '0px'
      }
    })
  },
  methods: {
    isActive(id) {
      return this.answers.filter(v => v.question_id === id).every(v => v.value)
    },
    getOriginQuestions(list) {
      let arr = []
      list.forEach((v, i) => {
        if (v.has_child == 0) { // 无子题
          arr.push({
            order_id: v.order_id,
            id: v.question_id,
            index: i,
          })
        } else { // 有子题
          v.sub_questions.forEach((cv) => {
            arr.push({
              order_id: cv.order_id,
              id: cv.question_id,
              index: i
            })
          })
        }
      })
      return arr
    },
    handleFocusQuestion(index, childIndex) {
      this.$emit('handleFocusQuestion', index, childIndex)
    }
  },
}
</script>

<style lang="scss" scoped>
.sidebar {
  background: white;
  width: 358px;
  border-bottom-right-radius: 25px;
  height: fit-content;
  flex-shrink: 0;
  margin-left: 12px;
  &.isFixed {
    position: relative;
  }
  .sidebar-middle {
    padding: 12px 30px 10px 24px;
    border-bottom: 1px solid #ECECEC;
    .middle-top {
      justify-content: space-between;
      .middle-top-title {
        font-size: 18px;
        color: #333;
      }
      .middle-top-section {
        &:last-child {
          margin-left: 32px;
        }
        .section-bg {
          display: inline-block;
          width: 9px;
          height: 9px;
          border-radius: 2px;
          margin-right: 2px;
          &.unfinish {
            background: #ECECEC;
          }
          &.finish {
            background: #68B8E6;
          }
        }
        .section-title {
          font-size: 14px;
          color: #999;
        }
      }
    }
    .middle-bottom {
      margin-top: 10px;
      font-size: 14px;
      color: #333;
      .finish-count {
        color: #68B8E6;
      }
      .unfinish-count {
        color: #EB3535;
      }
    }
  }
  .sidebar-bottom {
    padding: 20px 0 32px 24px;
    .sidebar-subquestions {
      margin-top: 30px;
      &:first-child {
        margin-top: 0;
      }
      .subquestions-title {
        color: #555;
        font-size: 14px;
      }
      .subquestions-nos {
        flex-wrap: wrap;
        .no {
          display: inline-block;
          min-width: 28px;
          height: 28px;
          line-height: 28px;
          text-align: center;
          background: #ECECEC;
          border-radius: 4px;
          color: #999;
          font-size: 16px;
          flex-shrink: 0;
          margin-right: 24px;
          margin-top: 16px;
          cursor: pointer;
          &.active {
            background: #68B8E6;
            color: white;
          }
        }
      }
    }
  }
  &.isPad {
    position: fixed;
    right: 39px;
    top: 50%;
    transform: translateY(-50%);
    border: 1px solid #ECECEC;
    border-radius: 10px;
  }
}
</style>