<template>
  <div class="fenxi" v-loading="loading" :class="{isPad: app.isPad}">
    <div class="header fc">
      <div class="fr header-top">
        <h2 class="b">{{paperObj.title}}</h2> 
        <el-radio-group v-model="radioValue" @change="handleChange" v-if="paperObj.is_report === 1">
          <el-radio-button label="学习报告"></el-radio-button>
          <el-radio-button label="试卷答案"></el-radio-button>
        </el-radio-group>
      </div>
      <div class="header-con" >
        <p class="tc"> <span v-if="paperObj.type < 3">总时长：{{paperObj.test_duration}}分钟  -  </span> <span v-if="paperObj.type === 1">满分：{{paperObj.score}}分</span>  </p>
        <div class="header-table">
          <table>
            <tr>
              <td>习题数量</td>
              <td>错题数量</td>
              <td>耗时</td>
              <td v-if="paperObj.type === 1">试卷难度</td>
              <td v-if="paperObj.type === 1">得分</td>
            </tr>
            <tr>
              <td>{{questionSize}}</td>
              <td>{{paperObj.mistakes}}</td>
              <td>{{paperObj.consume}}分钟</td>
              <td v-if="paperObj.type === 1">{{sjlevel(paperObj.level)}}</td>
              <td v-if="paperObj.type === 1" class="orange b">{{paperObj.student_score}}</td>
            </tr>
          </table>
        </div>
      </div>
      <span class="back" @click="$router.replace('/mypaper')">&lt;&lt;&nbsp;返回</span>
    </div>
        
    <div class="fr footer">
      <div class="answers">
        <div class="answer-container" ref="mathjaxContainer">
          <div class="question-item" v-for="(item, index) in questionList" ref="questons" :key="index">
            <h3 class="b">
              {{figure[index]}}、{{item.origin_cate_name}}题（共{{item.questions.length}}题
              <span v-if="!isWrongQuestion">，总共{{getAllScrore(item)}}分</span>）
            </h3>
            <div
              class="subquestion-item"
              :class="{active: index === activeIndex && childIndex === activeSubIndex}"
              v-for="(childItem, childIndex) in item.questions"
              :key="childIndex"
            >
              <!-- 单选 -->
              <div v-if="item.question_type === 101">
                <div class="subquestion-title fr">
                  <div class="fr sub-left">
                    <span class="sub-no">{{childItem.order_id}}、</span>
                    <span class="sub-desc" v-html="childItem.question" :style="{width: childItem.w + 'px'}"></span>
                  </div>
                  <Score 
                    v-if="!isWrongQuestion"
                    :childItem="childItem"
                  />
                </div>
                <div class="fc option-list">
                  <div
                    v-for="(sonItem, sonIndex) in childItem.content"
                    :key="sonItem.option_id"
                    class="fr option-item"
                    :class="{right: childItem.content.findIndex(v => v.flag === 1) === sonIndex, wrong: sonIndex === getWrongIndex(childItem)}"
                  >
                    <span>{{answerOptions[sonIndex]}}、</span>
                    <span v-html="sonItem.value"></span>
                    <img v-if="sonIndex === childItem.content.findIndex(v => v.flag === 1)" src="../assets/images/answer_right.png" alt="">
                    <img v-if="sonIndex === getWrongIndex(childItem)" src="../assets/images/answer_wrong.png" alt="">
                  </div>
                </div>
                <div class="analysis">
                  <div class="fc analy-header">
                    <div class="fr header-top">
                      <div class="fr header-left b">
                        <div class="my-answer">
                          我的答案<span v-for="(sonItem, sonIndex) in childItem.client_answer" :key="sonIndex">{{getMyAnswer(item.question_type, sonItem, childItem.content)}}</span>
                        </div>
                        <AnswerResult :result="childItem.submit_result" />
                      </div>
                      <div class="header-right" @click="childItem.isShow = !childItem.isShow">
                        题目解析
                        <img :src="childItem.isShow ? require('../assets/images/top_arrow.png') : require('../assets/images/bottom_arrow.png')" alt="">
                      </div>
                    </div>
                    <div class="header-bottom b">
                      标准答案<span>{{getOriginAnswer(childItem)}}</span>
                    </div>
                  </div>
                  <div class="analy-footer" v-show="childItem.isShow">
                    <div class="footer-desc fr">
                      <span class="b">题目解析</span>
                      <span v-html="childItem.question_explain"></span>
                    </div>
                    <div class="footer-video fr" v-if="childItem.answer_videos">
                      <span class="b">视频解析</span>
                      <video
                        v-if="childItem.answer_videos"
                        :src="childItem.answer_videos"
                        controls
                        width="50%"
                        height="auto"
                      ></video>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 判断 -->
              <div v-else-if="item.question_type === 200">
                <div class="subquestion-title fr">
                  <div class="sub-left fr">
                    <span class="sub-no">{{childItem.order_id}}、</span>
                    <span class="sub-desc" v-html="childItem.question" :style="{width: childItem.w + 'px'}"></span>
                  </div>
                  <Score 
                    v-if="!isWrongQuestion" 
                    :childItem="childItem"
                  />
                </div>
                <div class="fc option-list">
                  <div class="fr option-item" :class="{right: childItem.content[0].value === '1', wrong: childItem.content[0].value === '0'}">
                    <span>正确</span>
                    <img src="../assets/images/answer_right.png" alt="" v-if="childItem.content[0].value === '1'">
                    <img src="../assets/images/answer_wrong.png" alt="" v-if="childItem.content[0].value === '0'">
                  </div>
                  <div class="fr option-item" :class="{right: childItem.content[0].value === '0', wrong: childItem.content[0].value === '1'}">
                    <span>错误</span>
                    <img src="../assets/images/answer_right.png" alt="" v-if="childItem.content[0].value === '0'">
                    <img src="../assets/images/answer_wrong.png" alt="" v-if="childItem.content[0].value === '1'">
                  </div>
                </div>
                <div class="analysis">
                  <div class="fc analy-header">
                    <div class="fr header-top">
                      <div class="fr header-left b">
                        <div class="my-answer">
                          我的答案<span>{{childItem.client_answer.length ? (childItem.client_answer[0].value === '0' ? '错误' : (childItem.client_answer[0].value === '1' ? '正确' : '')) : ''}}</span>
                        </div>
                        <AnswerResult :result="childItem.submit_result" />
                      </div>
                      <div class="header-right" @click="childItem.isShow = !childItem.isShow">
                        题目解析
                        <img :src="childItem.isShow ? require('../assets/images/top_arrow.png') : require('../assets/images/bottom_arrow.png')" alt="">
                      </div>
                    </div>
                    <div class="header-bottom b">
                      标准答案<span>{{childItem.content[0].value === '0' ? '错误' : '正确'}}</span>
                    </div>
                  </div>
                  <div class="analy-footer" v-show="childItem.isShow">
                    <div class="footer-desc fr">
                      <span class="b">题目解析</span>
                      <span v-html="childItem.question_explain"></span>
                    </div>
                    <div class="footer-video fr" v-if="childItem.answer_videos">
                      <span class="b">视频解析</span>
                      <video
                        v-if="childItem.answer_videos"
                        :src="childItem.answer_videos"
                        controls
                        width="50%"
                        height="auto"
                      ></video>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 填空 -->
              <div v-else-if="item.question_type === 300">
                <div class="subquestion-title fr">
                  <div class="sub-left fr">
                    <span class="sub-no">{{childItem.order_id}}、</span>
                    <span class="sub-desc" v-html="childItem.question" :style="{width: childItem.w + 'px'}"></span>
                  </div>
                  <Score 
                    v-if="!isWrongQuestion" 
                    :childItem="childItem"
                  />
                </div>
                <div class="analysis">
                  <div class="fc analy-header">
                    <div class="fr header-top">
                      <div class="fr header-left">
                        <span class="left-title b">我的答案</span>
                        <div class="answer-list">
                          <div class="fr answer-item" v-for="(sonItem, sonIndex) in childItem.client_answer" :key="sonIndex">
                            <div class="fr item-left">
                              <span class="answer-item-title" v-if="childItem.client_answer.length>1">{答案{{sonIndex+1}}}</span>
                              <span class="latex">{{getMyAnswer(item.question_type, sonItem, childItem.content)}}</span>
                            </div>
                            <AnswerResult :result="childItem.submit_result" />
                          </div>
                        </div>
                      </div>
                      <div class="header-right" @click="childItem.isShow = !childItem.isShow">
                        题目解析
                        <img :src="childItem.isShow ? require('../assets/images/top_arrow.png') : require('../assets/images/bottom_arrow.png')" alt="">
                      </div>
                    </div>
                    <div class="fr header-bottom bottom">
                      <div class="header-bottom-left b fr" :class="{'is-first': childItem.content.length === 1}">
                        标准答案
                      </div>
                      <div class="fc header-bottom-right">
                        <div 
                          v-for="(sonItem, sonIndex) in childItem.content" 
                          :key="sonIndex"
                          class="correct-answer-item"
                          :class="{'is-first-item': childItem.content.length === 1}"
                        >
                          <p v-if='childItem.content.length>1'>{答案{{sonIndex+1}}}<span v-if="sonItem.answer_content_latex.length>1">（*填写以下答案其中一项即为正确）</span></p>
                          <span 
                            class="latex"
                            v-for="(cSonItem, cSonIndex) in sonItem.answer_content_latex" 
                            :key="cSonIndex"
                          >{{cSonItem}}<span v-if="cSonIndex + 1 !== sonItem.answer_content_latex.length">，</span></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="analy-footer" v-show="childItem.isShow">
                    <div class="footer-video fr" v-if="childItem.answer_videos">
                      <span class="b">视频解析</span>
                      <video
                        v-if="childItem.answer_videos"
                        :src="childItem.answer_videos"
                        controls
                        width="50%"
                        height="auto"
                      ></video>
                    </div>
                    <div class="footer-desc fr">
                      <span class="b">题目解析</span>
                      <span v-html="childItem.question_explain"></span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 解答 -->
              <div v-else-if="item.question_type === 400">
                <div class="subquestion-title fr">
                  <div class="sub-left fr">
                    <span class="sub-no">{{childItem.order_id}}、</span>
                    <span class="sub-desc" v-html="childItem.question" :style="{width: childItem.w + 'px'}"></span>
                  </div>
                  <Score 
                    v-if="childItem.has_child == 0 && !isWrongQuestion" 
                    :childItem="childItem"
                  />
                </div>
                <div v-if="childItem.has_child == 1" v-for="(sonChildItem, sonChildIndex) in childItem.sub_questions" :key="sonChildIndex" class="sonGrap">
                  <div class="subquestion-title fr">
                    <div class="sub-left fr">
                      <span class="sub-no">{{childItem.order_id}}-{{sonChildIndex + 1}}、</span>
                      <div class="sub-desc" v-html="sonChildItem.question" :style="{width: sonChildItem.w + 'px'}"></div>
                    </div>
                    <Score 
                      v-if="!isWrongQuestion" 
                      :childItem="sonChildItem"
                    />
                  </div>
                  <div class="analysis">
                    <div class="fc analy-header">
                      <div class="fr header-top">
                        <div class="fr header-left">
                          <span class="left-title b">我的答案</span>
                          <div class="answer-list">
                            <div class="fr answer-item" v-for="(sonItem, sonIndex) in sonChildItem.content" :key="sonIndex">
                              <div class="fr item-left">
                                <span class="answer-item-title" v-if="sonChildItem.content.length > 1">{答案{{sonIndex+1}}}</span>
                                <span class="latex">{{sonChildItem.client_answer[sonIndex].value}}</span>
                              </div>
                              <AnswerResult :result="sonChildItem.client_answer[sonIndex].submit_result" />
                            </div>
                          </div>
                        </div>
                        <div class="header-right" @click="sonChildItem.isShow = !sonChildItem.isShow">
                        题目解析
                          <img :src="sonChildItem.isShow ? require('../assets/images/top_arrow.png') : require('../assets/images/bottom_arrow.png')" alt="">
                        </div>
                      </div>
                      <div class="fr header-bottom bottom">
                        <div class="header-bottom-left b fr" :class="{'is-first': sonChildItem.content.length === 1}">
                          标准答案
                        </div>
                        <div class="fc header-bottom-right">
                          <div 
                            v-for="(sonItem, sonIndex) in sonChildItem.content" 
                            :key="sonIndex" 
                            class="correct-answer-item"
                            :class="{'is-first-item': sonChildItem.content.length === 1}"
                          >
                            <p v-if="sonChildItem.content.length>1">{答案{{sonIndex+1}}} <span v-if="sonItem.answer_content_latex.length>1">（*填写以下答案其中一项即为正确）</span></p>
                            <span 
                              v-for="(cSonItem, cSonIndex) in sonItem.answer_content_latex" 
                              :key="cSonIndex"
                              class="latex"
                            >{{cSonItem}}<span 
                              v-if="cSonIndex + 1 !== sonItem.answer_content_latex.length"
                            >，</span></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="analy-footer" v-show="sonChildItem.isShow">
                      <div class="footer-desc fr">
                        <span class="b">题目解析</span>
                        <span v-html="sonChildItem.question_explain"></span>
                      </div>
                      <div class="footer-video fr" v-if="sonChildItem.answer_videos">
                        <span class="b">视频解析</span>
                        <video
                          v-if="sonChildItem.answer_videos"
                          :src="sonChildItem.answer_videos"
                          controls
                          width="50%"
                          height="auto"
                        ></video>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="analysis" v-if="childItem.has_child == 0">
                  <div class="fc analy-header">
                    <div class="fr header-top">
                      <div class="fr header-left">
                        <div class="left-title b">我的答案</div>
                        <div class="answer-list">
                          <div class="fr answer-item" v-for="(sonItem, sonIndex) in childItem.content" :key="sonIndex">
                            <div class="fr item-left">
                              <span class="answer-item-title" v-if="childItem.content.length>1">{答案{{sonIndex+1}}}</span>
                              <span class="latex">{{childItem.client_answer[sonIndex].value}}</span>
                            </div>
                            <AnswerResult :result="childItem.client_answer[sonIndex].submit_result" />
                          </div>
                        </div>
                      </div>
                      <div class="header-right" @click="childItem.isShow = !childItem.isShow">
                        题目解析
                        <img :src="childItem.isShow ? require('../assets/images/top_arrow.png') : require('../assets/images/bottom_arrow.png')" alt="">
                      </div>
                    </div>
                    <div class="fr header-bottom bottom">
                      <div class="header-bottom-left b fr" :class="{'is-first': childItem.content.length === 1}">
                        标准答案
                      </div>
                      <div class="fc header-bottom-right">
                        <div 
                          v-for="(sonItem, sonIndex) in childItem.content" :key="sonIndex"
                          class="correct-answer-item"
                          :class="{'is-first-item': childItem.content.length === 1}"
                        >
                          <p v-if="childItem.content.length > 1">{答案{{sonIndex+1}}}<span v-if="sonItem.answer_content_latex.length>1">（*填写以下答案其中一项即为正确）</span></p>
                          <span class="latex" v-for="(cSonItem, cSonIndex) in sonItem.answer_content_latex" :key="cSonIndex">{{cSonItem}}<span v-if="cSonIndex + 1 !== sonItem.answer_content_latex.length">，</span></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="analy-footer" v-show="childItem.isShow">
                    <div class="footer-desc fr">
                      <span class="b">题目解析</span>
                      <span v-html="childItem.question_explain"></span>
                    </div>
                    <div class="footer-video fr" v-if="childItem.answer_videos">
                      <span class="b">视频解析</span>
                      <video
                        v-if="childItem.answer_videos"
                        :src="childItem.answer_videos"
                        controls
                        width="50%"
                        height="auto"
                      ></video>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="daohang fc" v-if="app.isPad" @click="isHideSheet = !isHideSheet">
          <img src="../assets/images/datika.png" alt="">
          <span>答题卡</span>
        </div>
      </div>
      <div 
        class="sidebar" 
        :class="{
          isFixed: isFixed,
          isPad: app.isPad
        }" 
        v-if="app.isPad ? isHideSheet : true"
        :style="styleObject"
        >
        <div class="sidebar-middle">
          <div class="middle-top fr">
            <span class="middle-top-title b">答题卡</span>
            <div class="middle-top-group fr">
              <div class="middle-top-section">
                <span class="section-bg unfinish"></span>
                <span class="section-title">答对</span>
              </div>
              <div class="middle-top-section">
                <span class="section-bg finish"></span>
                <span class="section-title">答错</span>
              </div>
            </div>
          </div>
          <div class="middle-bottom">
            本卷共 <span class="finish-count"> {{questionSize}} </span>题，答对<span class="finish-count"> {{questionSize-paperObj.mistakes}} </span>道题
          </div>
        </div>
        <div class="sidebar-bottom">
          <div class="sidebar-subquestions" v-for="(item, index) in questionList" :key="index">
            <span class="subquestions-title">{{item.origin_cate_name}}题（{{item.questions.length}}）</span>
            <div class="subquestions-nos fr">
              <span 
                class="no"
                :class="{active: childItem.submit_result === 1}"
                v-for="(childItem, childIndex) in getOriginQuestions(item.questions)"
                :key="childIndex"
                @click="handleFocusQuestion(index, childItem.index)"
              >
                {{childItem.order_id}}
              </span>
            </div>
          </div>
          <div class="fr sidebar-btns">
            <span @click="wrongTopic" v-if="(paperObj.type === 1 || paperObj.type === 2) && paperObj.correct_rate < 100 && !paperObj.mistake_generated">错题巩固</span>
            <span @click="intensiveStudy" v-if="(paperObj.type === 1 || paperObj.type === 2) && paperObj.correct_rate >= 80 && !paperObj.streng_generated">强化提高</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {paperResult, wrongPaperDetail, studyPaperDetail} from 'api'

import { ERROR_CODE } from '@/utils/config'
import { ANSWER_OPTIONS, FIGURE } from '@/utils/config';

import Star from 'comps/star'
import AnswerSheet from 'comps/answerSheet.vue'
import Score from 'comps/score'
import AnswerResult from 'comps/answerResult'
export default {
  data() {
    return {
      radioValue: '试卷答案',
      id: null,
      paperObj: {},
      // 题目列表
      questionList: [],
      // 所有答案选项
      answerOptions: ANSWER_OPTIONS,
      // 一、二、三...
      figure: FIGURE,
      loading: false,
      wrongLoading: false,
      activeIndex: -1,
      activeSubIndex: -1,
      isFixed: false,
      isHideSheet: false,
      styleObject: {
        top: '0px'
      }
    }
  },
  components: {
    Star,
    AnswerSheet,
    Score,
    AnswerResult,
  },
  inject: ['app'],
  mounted () {
    this.queryPaperAnswer()

    if (this.app.isPad) {
      this.styleObject = null
      return
    }

    window.addEventListener('scroll', () => {
      let scrollTop = document.documentElement.scrollTop
      this.styleObject.top = '0px'
      if (scrollTop > 460) {
        this.isFixed = true
        
        this.styleObject.top = parseInt(scrollTop - 460) + 'px'
      } else {
        this.isFixed = false
        this.styleObject.top = '0px'
      }
    })
  },
  computed: {
    // 计算习题数量
    questionSize() {
      let size = 0
      this.questionList.forEach(v => {
        size += v.questions.length
      })
      return size 
    },
    // 错题巩固/强化提高
    isWrongQuestion() {
      return this.paperObj.type !== 1
    }
  },
  methods: {
    getOriginQuestions(list) {
      let arr = []
      list.forEach((v, i) => {
        if (v.has_child == 0) { // 无子题
          arr.push({
            submit_result: v.submit_result,
            order_id: v.order_id,
            index: i
          })
        } else { // 有子题
          v.sub_questions.forEach((cv, ci) => {
            arr.push({
              submit_result: cv.submit_result,
              order_id: cv.order_id,
              index: i
            })
          })
        }
      })
      return arr
    },
    handleFocusQuestion(index, childIndex) {
      let el = this.$refs.mathjaxContainer
      let questionComp = el.querySelectorAll('.question-item')[index]
      let subquestionComp = questionComp.querySelectorAll('.subquestion-item')[childIndex]
      subquestionComp.scrollIntoView()
      this.activeIndex = index
      this.activeSubIndex = childIndex
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.activeIndex = -1
        this.activeSubIndex = -1
      }, 1200)
    },
    // 获取错误答案的索引
    getWrongIndex(item) {
      if (item.client_answer.length === 0) {
        return -1
      }

      let option_id = item.client_answer[0].option_id
      let idx = item.content.findIndex(v => v.flag === 0 && v.option_id === option_id)
      return idx
    },
    // 获取正确答案
    getOriginAnswer(item) {
      return this.answerOptions[item.content.findIndex(v=> v.flag === 1)]
    },
    // 获取我填写的答案
    getMyAnswer(type, item, content) {
      if (type === 101) { // 单选题
        let option_id = item.option_id
        let findIdx = content.findIndex(v => v.option_id === option_id)
        return this.answerOptions[findIdx]
      } else if (type === 300) { // 填空题
        return item.value
      } else {
        return null
      }
    },
    // 试卷难度
    sjlevel(level) {
      var leveltext=level==1||level==2?'简单':level==3||level==4?'一般':level==5||level==6?'较难':'困难';
      return leveltext;
    },
    // 获取单个题型的总分值
    getAllScrore({questions}) {
      return questions.reduce((sum, e) => sum + e.score, 0)
    },
    // 查看试卷答案
    async queryPaperAnswer() {
      this.id = this.$route.query.id
      this.loading = true
      let {error, data} = await paperResult({report_id: this.id})
      this.loading = false
      if (error === ERROR_CODE) {
        this.paperObj = data
        this.questionList = data.question_list || []
        this.questionList = this.questionList.map(v => {
          v.questions = v.questions.map((cv, ci) => ({
            ...cv,
            isShow: false,
            sub_questions: cv.sub_questions.map((scv, sci) => ({...scv, order_id: `${cv.order_id}-${sci + 1}`, isShow: false}))
          }))
          return v
        })
        this.questionList = this.questionList.filter(v => (
          v.question_type === 101 || 
          v.question_type === 200 ||
          v.question_type === 300 || 
          v.question_type == 400
        ))
        this.renderLatex()

        this.$nextTick(() => {
          let parentElems = document.querySelectorAll('.question-item')
          this.questionList = this.questionList.map((v, idx) => {
            let parentElem = parentElems[idx]
            if (v.question_type !== 400) {
              let childs = parentElem.querySelectorAll('.subquestion-item .sub-desc')
              v.questions = v.questions.map((cv, cIdx) => {
                let child = childs[cIdx]
                let childW = child.offsetWidth
                cv.w = childW
                return cv
              })
              return v
            } else {
              v.questions = v.questions.map((cv, cIdx) => {
                if (cv.has_child === 0) { // 无子题
                  let childs = parentElem.querySelectorAll('.subquestion-item .sub-desc')
                  let child = childs[cIdx]
                  let childW = child.offsetWidth
                  cv.w = childW
                  return cv
                } else { // 有子题
                  let parentChilds = parentElem.querySelectorAll('.subquestion-item')
                  let child = parentChilds[cIdx]
                  let childs = child.querySelectorAll('.sub-desc')
                  cv.w = childs[0].offsetWidth
                  
                  cv.sub_questions = cv.sub_questions.map((scv, scIdx) => {
                    let sonChilds = child.querySelectorAll('.sonGrap .sub-desc')
                    let sonChild = sonChilds[scIdx]
                    scv.w = sonChild.offsetWidth
                    return scv
                  })
                  return cv
                }
              })
              return v
            }
          })
        })
        this.renderMathml()
      }
    },
    getMq() {
      if (!this.MQ) {
        this.MQ = MathQuill.getInterface(2);
      }
      return this.MQ;
    },
    /**渲染latex */
    renderLatex() {
      this.$nextTick(() => {
        let elems = document.querySelectorAll('.latex')
        elems.forEach(v => this.getMq().StaticMath(v))
      })
    },
    // 渲染mathjax公式
    renderMathml() {
      this.$nextTick(() => {
        if (this.commonsVariable.isMathjaxConfig === false) {
          // 如果：没有配置MathJax
          this.commonsVariable.initMathjaxConfig();
        }
        let elem = this.$refs.mathjaxContainer;
        this.commonsVariable.MathQueue(elem);
      })
    },
    // 错题巩固
    async wrongTopic() {
      this.wrongLoading = true
      let {error, data = {}} = await wrongPaperDetail({report_id: this.id})
      this.wrongLoading = false
      if (error === ERROR_CODE) {
        let id = data.new_report_id
        this.$router.push({
          path: '/wrongtopic',
          query: {
            id,
          }
        })
      }
    },
    // 强化提高
    async intensiveStudy() {
      let {error, data = {}} = await studyPaperDetail({report_id: this.id})
      if (error === ERROR_CODE) {
        let id = data.new_report_id
        this.$router.push({
          path: '/intensivestudy',
          query: {
            id
          }
        })
      }
    },
    handleChange() {
      this.$router.push({
        path: '/studyreport',
        query: {
          id: this.id
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.orange {
  color: #EA6B51;
  font-size: 30px;
} 
::v-deep table {
  margin-top: 5px;
  margin-bottom: 4px;
  td {
    border: 1px solid grey;
  }
}
.sonGrap {
  margin-left: 30px;
  color: #555;
  &:not(first-child) {
    margin-top: 30px;
  }
}
.fenxi {
  &.isPad {
    .header h2 {
      font-size: 24px !important;
    }
    .header-footer {
      justify-content: space-around !important;
      div {
        margin-right: 0 !important;
      }
      .footer-section {
        margin-right: 0 !important;
      }
    }
  }
  .header {
    padding: 30px 0;
    background: #FFFFFF;
    border-radius: 25px 25px 0px 0px;
    margin-top: 30px;
    position: relative;
    .back {
      position: absolute;
      left: 20px;
      top: 20px;
      padding: 10px;
      font-size: 18px;
      color: #333;
      cursor: pointer;
    }
    .header-top {
      justify-content: center;
      align-items: center;
      position: relative;
      h2 {
        font-size: 28px;
        color: #333;
      }
      .el-radio-group {
        position: absolute;
        right: 18px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .header-con{
      padding:28px 50px 40px 40px;
      color:#555;
      p:first-child {
        font-size: 20px;
      }
      .header-table{
        overflow: hidden;
        border-radius: 22px;
        text-align: center;
        border:1px solid #EDEDED;
        margin: 24px 0 13px 0;
        table{
          border:none;
          width:100%;
          margin: 0;
          tr{
            border:none;
            border-top:1px solid #EDEDED;
            color:#333;
            font-size: 22px;
            height: 57px;
            line-height: 57px;
            &:first-child {
              border-top:none;
              color:#555;
              font-size: 20px;
              height: 54px;
              line-height: 54px;
            }
          }
          td{
            border:none;
            border-right:1px solid #EDEDED;
          }
        }
      }
    }
    .header-footer {
      margin-top: 25px;
      align-items: center;
      justify-content: center;
      div {
        margin-right: 60px;
        color: #555;
        font-size: 18px;
        &:last-child {
          margin-right: 0;
        }
      }
      .paper-grade {
        align-items: center;
        margin-right: 50px;
      }
      .footer-section {
        color: #333;
        margin-right: 50px;
        &.last {
          margin-right: 0;
        }
      }
    }
  }
  .footer {
    margin-top: 12px;
    .answers {
      background: white;
      width: 100%;
      box-sizing: border-box;
      padding: 28px 50px 40px 40px;
      border-bottom-left-radius: 25px;
      .answer-container {
        margin-right: 12px;
        padding-top: 50px;
        .question-item {
          margin-top: 60px;
          &:first-child {
            margin-top: 0;
          }
          h3 {
            font-size: 18px;
            color: #333;
            margin-bottom: 40px;
          }
          .subquestion-item {
            margin-bottom: 65px;
            &:last-child {
              margin-bottom: 0;
            }
            .subquestion-title {
              font-size: 18px;
              color: #555;
              margin-bottom: 40px;
              line-height: 1.5;
              justify-content: space-between;
              align-items: baseline;
              & > div:first-child {
                white-space: break-spaces;
              }
              .sub-left {
                align-items: baseline;
                width: 100%;
                .sub-no {
                  flex-shrink: 0;
                }
                .sub-desc {
                  width: 100%;
                  overflow-x: auto;
                  overflow-y: hidden;
                }
              }
            }
            .option-item {
              align-items: center;
              &:first-child {
                margin-top: 0;
              }
              margin-top: 15px;
              &.right {
                color: #34B979;
              }
              &.wrong {
                color: #EA6B52;
              }
              img {
                width: 15px;
                margin-left: 15px;
              }
            }
            .analysis {
              background: white;
              border-radius: 10px;
              overflow: hidden;
              margin-top: 20px;
              .analy-header {
                justify-content: space-between;
                font-size: 16px;
                color: #555;
                align-items: baseline;
                .header-top {
                  width: 100%;
                  justify-content: space-between;
                  background: #F7F7F7;
                  padding: 24px 20px;
                  box-sizing: border-box;
                  .header-left {
                    align-items: baseline;
                    flex-grow: 1;
                    padding-right: 90px;
                    .left-title {
                      flex-shrink: 0;
                      font-size: 18px;
                      color: #555;
                    }
                    .answer-list {
                      margin-left: 18px;
                      width: 100%;
                      .answer-item {
                        justify-content: space-between;
                        flex-wrap: wrap;
                        margin-top: 24px;
                        line-height: 1.8;
                        &:first-child {
                          margin-top: 0;
                        }
                        .item-left {
                          flex-wrap: wrap;
                          align-items: center;
                          .answer-item-title {
                            flex-shrink: 0;
                            font-size: 18px;
                            color: #4DC187;
                            margin-right: 20px;
                          }
                          .latex {
                            font-size: 18px;
                          }
                        }
                      }
                    }
                    .my-answer {
                      font-size: 18px;
                      color: #555;
                      margin-right: 115px;
                      span {
                        font-size: 18px;
                        margin-left: 19px;
                        font-weight: normal;
                      }
                    }
                  }
                  .header-right {
                    color: #44BD81;
                    font-size: 16px;
                    align-items: center;
                    padding: 5px 10px;
                    flex-shrink: 0;
                    cursor: pointer;
                    img {
                      margin-left: 8px;
                      width: 14px;
                    }
                  }
                }
                .header-bottom {
                  background: #F7F7F7;
                  width: 100%;
                  margin-top: 6px;
                  padding: 24px 20px;
                  box-sizing: border-box;
                  font-size: 18px;
                  color: #555;
                  & > span {
                    margin-left: 19px;
                    font-size: 18px;
                    font-weight: normal;
                  }
                  &.bottom {
                    padding: 24px 0 24px 20px;
                  }
                  .header-bottom-left {
                    flex-shrink: 0;
                    margin-right: 18px;
                    align-items: baseline;
                    &.is-first {
                      align-items: center;
                    }
                  }
                  .header-bottom-right {
                    width: 100%;
                    .correct-answer-item {
                      border-bottom: 1px solid white;
                      line-height: 1.8;
                      &.is-first-item {
                        .latex {
                          margin: 0 !important;
                        }
                      }
                      &:last-child {
                        border-bottom: none;
                      }
                      p {
                        font-size: 18xp;
                        color: #4DC187;
                        margin-bottom: 11px;
                        margin-top: 30px;
                        line-height: 1;
                      }
                      .latex {
                        margin: 19px 10px 19px 0;
                        font-size: 18px;
                        &:last-child {
                          margin-bottom: 30px;
                        }
                      }
                      &:first-child {
                        p {
                          margin-top: 0;
                        }
                      }
                    }
                  }
                }
              }
              .analy-footer {
                .footer-desc {
                  font-size: 18px;
                  margin-top: 6px;
                  align-items: center;
                  background: #F7F7F7;
                  padding: 24px 20px;
                  span {
                    &:first-child {
                      color: #3DBC7C;                    
                      margin-right: 20px;
                      flex-shrink: 0;
                    }
                    &:last-child {
                      overflow-x: auto;
                      overflow-y: hidden;
                      line-height: 1.5;
                      font-size: 18px;
                      color: #555;
                    }
                  }
                  ::v-deep img {
                    max-width: 80%;
                  }
                }
                .footer-video {
                  padding: 24px 20px;
                  align-items: center;
                  margin-top: 6px;
                  font-size: 18px;
                  color: #1FB268;
                  background: #F7F7F7;
                  video {
                    margin-left: 20px;
                  }
                }
              }
            }
            &.active {
              animation: myanimation 1.2s;
              @keyframes myanimation {
                0% {
                  background: transparent;
                }
                50% {
                  background: rgb(83, 159, 255);
                }
                100% {
                  background: transparent;
                }
              }
            }
          }
        }
      }
      .daohang {
        position: fixed;
        right: 0;
        background: #45BEF1;
        top: 50%;
        transform: translateY(-50%);
        width: 39px;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        align-items: center;
        font-size: 16px;
        color: white;
        cursor: pointer;
        img {
          width: 19px;
          padding: 8px 0;
        }
        span {
          writing-mode: vertical-lr;
          padding-bottom: 8px;
          letter-spacing: 1px;
        }
      }
    }
    .sidebar {
      background: white;
      width: 358px;
      border-bottom-right-radius: 25px;
      height: fit-content;
      flex-shrink: 0;
      margin-left: 12px;
      &.isFixed {
        position: relative;
      }
      .sidebar-middle {
        padding: 12px 30px 10px 24px;
        border-bottom: 1px solid #ECECEC;
        .middle-top {
          justify-content: space-between;
          .middle-top-title {
            font-size: 18px;
            color: #333;
          }
          .middle-top-section {
            &:last-child {
              margin-left: 32px;
            }
            .section-bg {
              display: inline-block;
              width: 9px;
              height: 9px;
              border-radius: 2px;
              margin-right: 2px;
              &.unfinish {
                background: #04964D;
              }
              &.finish {
                background: #CC3213;
              }
            }
            .section-title {
              font-size: 14px;
              color: #999;
            }
          }
        }
        .middle-bottom {
          margin-top: 10px;
          font-size: 14px;
          color: #333;
          .finish-count {
            color: #68B8E6;
          }
          .unfinish-count {
            color: #EB3535;
          }
        }
      }
      .sidebar-bottom {
        padding: 20px 0 32px 24px;
        .sidebar-subquestions {
          margin-top: 30px;
          &:first-child {
            margin-top: 0;
          }
          .subquestions-title {
            color: #555;
            font-size: 14px;
          }
          .subquestions-nos {
            flex-wrap: wrap;
            .no {
              display: inline-block;
              min-width: 32.5px;
              height: 32.5px;
              line-height: 32.5px;
              text-align: center;
              background: #CC3213;
              border-radius: 4px;
              color: white;
              font-size: 16px;
              flex-shrink: 0;
              margin-right: 23px;
              margin-top: 16px;
              cursor: pointer;
              &.active {
                background: #04964D;
                color: white;
              }
            }
          }
        }
        .sidebar-btns {
          margin-top: 40px;
          justify-content: space-between;
          padding-right: 24px;
          span {
            padding: 12px 17px;
            font-size: 18px;
            border-radius: 20px;
            cursor: pointer;
            &:first-child {
              border: 1px solid #68B8E6;
              color: #68B8E6;
              background: white;
            }
            &:last-child {
              background: #68B8E6;
              color: white;
              border: 1px solid #68B8E6;
            }
          }
        }
      }
      &.isPad {
        position: fixed;
        right: 39px;
        top: 50%;
        transform: translateY(-50%);
        border: 1px solid #ECECEC;
        border-radius: 10px;
      }
    }
  }
}
</style>